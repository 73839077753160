define("ember-bootstrap/version", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VERSION = void 0;
  _exports.registerLibrary = registerLibrary;
  var VERSION = (0, _runtime.config)("/codebuild/output/src4185904833/src/git-codecommit.us-east-1.amazonaws.com/v1/repos/nsf-etap-ui/node_modules/ember-bootstrap").version;
  _exports.VERSION = VERSION;

  function registerLibrary() {
    Ember.libraries.register('Ember Bootstrap', VERSION);
  }
});